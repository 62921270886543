import React, { FunctionComponent as FC } from 'react';
import { motion } from 'framer-motion';
import { Text, Button } from '@unom/unom-blocks';
import { ease } from '@unom/unom-style';
import styles from './CardPartner.module.css';

export interface Props {
    name: string;
    brand: string;
    website: string;
}

const CardPartner: FC<Props> = ({ name, brand, website }) => {
    const variants = {
        container: {
            from: { scale: 0 },
            enter: { scale: 1 },
            exit: { scale: 0 },
        },
    };

    return (
        <motion.div variants={variants.container} transition={ease.quint(0.6).out} className={styles.container}>
            <Text.h2>{brand}</Text.h2>
            <Text.h3>{name}</Text.h3>
            <Button href={website}>Website</Button>
        </motion.div>
    );
};

export default CardPartner;
