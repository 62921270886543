import Start from './Start';
import Introduction from './Introduction';
import Partner from './Partner';
import ComingSoon from 'Sections/ComingSoon';

const Sections = {
    Start: Start,
    Introduction: Introduction,
    Partner: Partner,
    ComingSoon: ComingSoon,
};

export default Sections;
