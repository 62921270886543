import React, { FunctionComponent as FC } from 'react';
import Start from './Start';
import Header from 'Components/Header';
import Legal from './Legal';
import { SiteError, Route as WrapperRoute } from '@unom/unom-blocks';
import { ScrollWrapper, scrollService } from '@unom/unom-scroll';
import { Switch, Route, Link } from 'react-router-dom';
import { ease } from '@unom/unom-style';
import { motion } from 'framer-motion';

const Sites: FC = () => {
    const variants = {
        initial: {
            scale: 0.5,
            opacity: 0,
        },
        enter: {
            scale: 1,
            opacity: 1,
        },
        exit: {
            opacity: 0,
            scale: 1.4,
        },
    };

    const commonRouteProps = {
        RouterLinkComponent: Link,
        RouterRouteComponent: Route,
    };

    return (
        <>
            <Header />
            <Route
                render={({ location }: { location: any }): JSX.Element => {
                    scrollService.scrollTo(0);

                    return (
                        <ScrollWrapper location={location.pathname} lerp={0.05}>
                            <motion.div
                                variants={variants}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                transition={{ ...ease.quint(0.9).out }}
                                key={location.pathname}
                            >
                                <Switch location={location}>
                                    <WrapperRoute {...commonRouteProps} path="/" exact>
                                        <Start />
                                    </WrapperRoute>
                                    <WrapperRoute {...commonRouteProps} path="/impressum">
                                        <Legal.Imprint />
                                    </WrapperRoute>
                                    <WrapperRoute {...commonRouteProps} path="/datenschutz">
                                        <Legal.Privacy />
                                    </WrapperRoute>
                                    <WrapperRoute {...commonRouteProps}>
                                        <SiteError code="404" />
                                    </WrapperRoute>
                                </Switch>
                            </motion.div>
                        </ScrollWrapper>
                    );
                }}
            />
        </>
    );
};

export default Sites;
