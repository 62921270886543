import React, { FunctionComponent as FC } from 'react';
import { Logo } from '@unom/unom-blocks';
import { motion } from 'framer-motion';
import styles from './Header.module.css';
import { Link } from '@unom/unom-blocks';
import { Link as RouterLinkComponent } from 'react-router-dom';
import AnimationComponent from 'react-lottie';

export interface Props {
    show?: boolean;
}

const Header: FC<Props> = ({ show = true }) => {
    return (
        <motion.div className={styles.container}>
            <div className={styles.contentContainer}>
                <Link RouterLinkComponent={RouterLinkComponent} to="/">
                    <Logo AnimationComponent={AnimationComponent} animated={true} />
                </Link>
            </div>
            <div className={styles.background} />
        </motion.div>
    );
};

export default Header;
