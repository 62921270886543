import React, { FC } from 'react';
import localStyles from './Site.module.css';
import { Helmet } from 'react-helmet';

export interface SiteProps {
    borderTop?: boolean;
    title?: string;
    styles?: {
        container?: string;
        borderTopDisabled?: string;
    };
}

const Site: FC<SiteProps> = ({
    borderTop = true,
    children,
    title = 'Kreativagentur aus Stuttgart',
    styles = localStyles,
}) => {
    return (
        <>
            <Helmet>
                <title>unom - {title}</title>
            </Helmet>
            <div className={`${styles.container} ${borderTop ? '' : styles.borderTopDisabled}`}>{children}</div>
            <div
                style={{
                    background: 'var(--gradient-neutral)',
                    height: '100vh',
                    top: 0,
                    width: '100%',
                    position: 'fixed',
                    zIndex: -1,
                }}
            ></div>
        </>
    );
};

export default Site;
