import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@unom/unom-style/index.css';
import '@unom/unom-blocks/build/unom-blocks.css';
import '@unom/unom-scroll/build/unom-scroll.css';
import 'fonts/stylesheet.css';
import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');

ReactDOM.render(<AppContainer />, root);

serviceWorker.unregister();
