import React, { FunctionComponent as FC } from 'react';
import styles from './SectionComingSoon.module.css';
import { motion } from 'framer-motion';
import { Text } from '@unom/unom-blocks';
import { ease } from '@unom/unom-style';

const SectionComingSoon: FC = () => {
    const variants = {
        container: {
            visible: { scale: 1 },
            invisible: { scale: 0 },
        },
    };

    return (
        <motion.div
            transition={{ staggerChildren: 0.4, ...ease.quint(1.6).out }}
            animate="visible"
            data-scroll
            data-scroll-delay="0.3"
            data-scroll-speed="3"
            initial="invisible"
            variants={variants.container}
            className={styles.container}
        >
            <Text.h1 extraStyles={{ marginBottom: '0.5em' }}>Diese Seite ist aktuell noch in Arbeit.</Text.h1>
            <Text.h3>Besuche uns doch bald wieder!</Text.h3>
        </motion.div>
    );
};

export default SectionComingSoon;
