import React, { FunctionComponent as FC } from 'react';
import { Section } from '@unom/unom-blocks';
import Sections from './Sections';
import Site from 'Components/Site';

const SiteStart: FC = () => {
    return (
        <>
            <Site borderTop={false}>
                <Section height="100vh">
                    <Sections.ComingSoon />
                </Section>
            </Site>
        </>
    );
};

export default SiteStart;
