import React, { FunctionComponent as FC } from 'react';
import { Text } from '@unom/unom-blocks';
import { partnerData } from './partnerData';
import { motion } from 'framer-motion';
import styles from './SectionPartner.module.css';
import PartnerCard from 'Components/Cards/Partner';

const SectionPartner: FC = () => {
    const cards = partnerData.map((partner) => (
        <PartnerCard name={partner.name} key={partner.id} website={partner.website} brand={partner.brand} />
    ));

    return (
        <div className={styles.container} data-scroll data-scroll-speed="1.3" style={{ height: '100vh' }}>
            <Text.h1>Partner</Text.h1>
            <motion.div className={styles.cardContainer}>{cards}</motion.div>
        </div>
    );
};

export default SectionPartner;
