import React, { FunctionComponent as FC } from 'react';
import { Text } from '@unom/unom-blocks';
import styles from './SectionIntroduction.module.css';

const SectionIntroduction: FC = () => {
    return (
        <div data-scroll data-scroll-speed="3.6" className={styles.container}>
            <Text.h1
                extraStyles={{
                    width: '550px',
                    lineHeight: 0.8,
                    textTransform: 'uppercase',
                    filter: 'drop-shadow(5px 0px 10px var(--color-secondary))',
                    webkitTextStroke: '3px var(--color-secondary)',
                    color: 'transparent',
                }}
                size="xxl"
            >
                Willkommen bei unom!
            </Text.h1>
        </div>
    );
};

export default SectionIntroduction;
