const partnerData = [
  {
    id: 0,
    name: "Tom Stehr",
    brand: "Stehr-Design",
    website: "https://stehr-design.de",
  },
  {
    id: 1,
    name: "Martin Zimmer",
    brand: "Martin-Zimmer-Design",
    website: "https://martinzimmerdesign.de",
  },
  {
    id: 2,
    name: "Jendrik Ludwig",
    brand: "Ludwig Media",
    website: "https://ludwig.media",
  },
];

export { partnerData };
