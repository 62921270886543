import React, { FunctionComponent as FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Sites from 'Sites';

const AppContainer: FC = () => {
    return (
        <Router>
            <Sites />
        </Router>
    );
};

export default AppContainer;
